
// Initial state for app reducer
const initialState = {
  articleContent: [],
  columnContent: [],
  countryLinks: [],
  keywordsAndSome: [],
  pictograms: [],
  promoBlocks: [],
  posts: [],
  relatedPosts: [],
  mainNavOpen: false,
  microcopy: [],
  navItems: [],
  numberBlocks: [],
  people: [],
  testimonials: [],
  userLocation: {},
  videos: [],
  countrySites:[
    {name: "Finland", site: "ramirent.fi"},
    {name: "Sweden", site: "ramirent.se"},
    {name: "Norway", site: "ramirent.no"},
    {name: "Estonia", site: "ramirent.ee"},
    {name: "Latvia", site: "ramirent.lv"},
    {name: "Lithuania", site: "ramirent.lt"},
    {name: "Poland", site: "ramirent.pl"},
    {name: "Slovakia", site: "ramirent.sk"},
    {name: "Czech Republic", site: "ramirent.cz"},
  ]
};

// REGISTER ACTION TYPES
const INIT_POSTS = 'INIT_POSTS';
const INIT_RELATED_POSTS = 'INIT_RELATED_POSTS';
const INIT_ARTICLE_CONTENT = 'INIT_ARTICLE_CONTENT';
const INIT_NAV_ITEMS = 'INIT_NAV_ITEMS';
const INIT_PROMO_BLOCKS = 'INIT_PROMO_BLOCKS';
const INIT_NUMBER_BLOCKS = 'INIT_NUMBER_BLOCKS';
const INIT_PEOPLE = 'INIT_PEOPLE';
const INIT_VIDEOS = 'INIT_VIDEOS';
const INIT_PICTOGRAMS = 'INIT_PICTOGRAMS';
const INIT_COUNTRYLINKS = 'INIT_COUNTRYLINKS';
const INIT_TESTIMONIALS = 'INIT_TESTIMONIALS';
const INIT_KEYWORDS_AND_SOME = 'INIT_KEYWORDS_AND_SOME';
const INIT_MICROCOPY = 'INIT_MICROCOPY';
const INIT_COLUMN_CONTENT = 'INIT_COLUMN_CONTENT';

const INIT_USER_LOCATION = 'INIT_USER_LOCATION';
const TOGGLE_MAIN_NAV_OPEN = 'TOGGLE_MAIN_NAV_OPEN';

// ACTIONS -------------------------------------------- |

// Initialize posts to state ---------------
export const initPosts = (posts) => {

  return ({
  type: INIT_POSTS, posts
})};

// -----------------------------------------

// Initialize related posts to state ---------------
export const initRelatedPosts = (posts) => {

  return ({
  type: INIT_RELATED_POSTS, posts
})};

// -----------------------------------------

// Initialize article content to state ---------------
export const initArticleContent = (content) => {

  return ({
  type: INIT_ARTICLE_CONTENT, content
})};

// -----------------------------------------

// Initialize navItems to state ---------------
export const initNavItems = (items) => {

  return ({
  type: INIT_NAV_ITEMS, items
})};

// -----------------------------------------


// Initialize promoBlocks to state ---------------
export const initPromoBlocks = (items) => {

  return ({
  type: INIT_PROMO_BLOCKS, items
})};

// -----------------------------------------


// Initialize numberBlock to state ---------------
export const initNumberBlocks = (items) => {

  return ({
  type: INIT_NUMBER_BLOCKS, items
})};

// -----------------------------------------

// Initialize videos to state ---------------
export const initVideos = (items) => {

  return ({
  type: INIT_VIDEOS, items
})};

// -----------------------------------------

// Initialize testimonials to state ---------------
export const initTestimonials = (items) => {

  return ({
  type: INIT_TESTIMONIALS, items
})};

// -----------------------------------------

// Initialize testimonials to state ---------------
export const initKeywordsAndSome = (items) => {

  return ({
  type: INIT_KEYWORDS_AND_SOME, items
})};

// -----------------------------------------

// Initialize microcopy to state ---------------
export const initMicrocopy = (items) => {
  
  return ({
  type: INIT_MICROCOPY, items
})};

// -----------------------------------------

// Initialize people to state ---------------
export const initPeople = (items) => {

  return ({
  type: INIT_PEOPLE, items
})};

// -----------------------------------------

// Initialize pictograms to state ---------------
export const initPictograms = (items) => {

  return ({
  type: INIT_PICTOGRAMS, items
})};

// -----------------------------------------

// Initialize countrylinks to state ---------------
export const initCountryLinks= (items) => {

  return ({
  type: INIT_COUNTRYLINKS, items
})};

// -----------------------------------------

// Initialize columnContent to state ---------------
export const initColumnContent = (items) => {

  return ({
  type: INIT_COLUMN_CONTENT, items
})};

// -----------------------------------------

// Initialize user location to state ---------------
export const initUserLocation = (loc) => {
  const countrySites = [
    {name: "Finland", site: "ramirent.fi"},
    {name: "Sweden", site: "ramirent.se"},
    {name: "Norway", site: "ramirent.no"},
    {name: "Estonia", site: "ramirent.ee"},
    {name: "Latvia", site: "ramirent.lv"},
    {name: "Lithuania", site: "ramirent.lt"},
    {name: "Poland", site: "ramirent.pl"},
    {name: "Slovakia", site: "ramirent.sk"},
    {name: "Czech Republic", site: "ramirent.cz"},
  ];
  const acceptedNames = ["Finland", "Sweden", "Norway", "Estonia", "Latvia", "Lithuania", "Poland", "Slovakia", "Czech Republic"];
  countrySites.forEach(country => {
    if(country.name === loc.name) {
      loc.site = country.site;
    } 
  });
  if(!acceptedNames.includes(loc.name)) {
    // set empty string if not ramirent country
    loc.name = '';
  }
  return ({
  type: INIT_USER_LOCATION, loc
})};

// -----------------------------------------

// Toggle main nav open ---------------
export const toggleMainNavOpen = (currentState) => {
  const open = !currentState;
  return ({
  type: TOGGLE_MAIN_NAV_OPEN, open
})};

// -----------------------------------------

// ACTIONS END --------------------------------------- |


// APP REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_POSTS:
      return { ...state, posts: action.posts };
    case INIT_RELATED_POSTS:
      return { ...state, relatedPosts: action.posts };
    case INIT_ARTICLE_CONTENT:
      return { ...state, articleContent: action.content };
    case INIT_NAV_ITEMS:
      return { ...state, navItems: action.items };
    case INIT_PROMO_BLOCKS:
      return { ...state, promoBlocks: action.items };
    case INIT_NUMBER_BLOCKS:
      return { ...state, numberBlocks: action.items };
    case INIT_VIDEOS:
      return { ...state, videos: action.items };
    case INIT_TESTIMONIALS:
      return { ...state, testimonials: action.items };
    case INIT_MICROCOPY:
      return { ...state, microcopy: action.items };
    case INIT_PEOPLE:
      return { ...state, people: action.items };
    case INIT_COLUMN_CONTENT:
      return { ...state, columnContent: action.items };
    case INIT_KEYWORDS_AND_SOME:
      return { ...state, keywordsAndSome: action.items };
    case INIT_USER_LOCATION:
      return { ...state, userLocation: action.loc };
    case TOGGLE_MAIN_NAV_OPEN:
        return { ...state, mainNavOpen: action.open };
    default:
      return state;
  }
};