// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keywords-index-js": () => import("./../../../src/pages/keywords/index.js" /* webpackChunkName: "component---src-pages-keywords-index-js" */),
  "component---src-pages-releases-index-js": () => import("./../../../src/pages/releases/index.js" /* webpackChunkName: "component---src-pages-releases-index-js" */),
  "component---src-pages-releasesfi-index-js": () => import("./../../../src/pages/releasesfi/index.js" /* webpackChunkName: "component---src-pages-releasesfi-index-js" */),
  "component---src-pages-validator-js": () => import("./../../../src/pages/validator.js" /* webpackChunkName: "component---src-pages-validator-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-post-index-js": () => import("./../../../src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */)
}

